<template>
	<aside>
		<a
			v-if="fields.agreementDetails !== undefined"
			href="#details"
			@click.prevent="scroll"
			class="aside-item"
			:class="{ success: fields.agreementDetails }"
		>
			Avtala
		</a>

		<a
			v-if="fields.customer !== undefined"
			href="#customer"
			@click.prevent="scroll"
			class="aside-item"
			:class="{ success: fields.customer }"
		>
			Upplýsingar um kundan
		</a>

		<a
			v-if="fields.services !== undefined"
			href="#services"
			@click.prevent="scroll"
			class="aside-item"
			:class="{ success: fields.services }"
		>
			Tænastur
		</a>

		<a
			v-if="fields.location !== undefined"
			href="#location"
			@click.prevent="scroll"
			class="aside-item"
			:class="{ success: fields.location }"
		>
			Sølustað
		</a>

		<a
			v-if="fields.owners !== undefined"
			href="#owners"
			@click.prevent="scroll"
			class="aside-item"
			:class="{ success: fields.owners }"
		>
			Persónar
		</a>

		<a
			v-if="fields.bankAccount !== undefined"
			href="#bank"
			@click.prevent="scroll"
			class="aside-item"
			:class="{ success: fields.bankAccount }"
		>
			Bankakonta
		</a>

		<a
			v-if="fields.ecom !== undefined"
			href="#ecom"
			@click.prevent="scroll"
			class="aside-item"
			:class="{ success: fields.ecom }"
		>
			Nethandilsavtala
		</a>

		<a
			v-if="fields.swedbank !== undefined"
			href="#swedbank"
			@click.prevent="scroll"
			class="aside-item"
			:class="{ success: fields.swedbank }"
		>
			Swedbank avtala
		</a>

		<a
			v-if="fields.terminals !== undefined"
			href="#terminals"
			@click.prevent="scroll"
			class="aside-item"
			:class="{ success: fields.terminals }"
		>
			Terminalar
		</a>

		<a
			v-if="fields.vibrant !== undefined"
			href="#vibrant"
			@click.prevent="scroll"
			class="aside-item"
			:class="{ success: fields.vibrant }"
		>
			Vibrant
		</a>

		<a
			v-if="fields.kyc !== undefined"
			href="#kyc"
			@click.prevent="scroll"
			class="aside-item"
			:class="{ success: fields.kyc }"
		>
			Upplýsingar um fyritøkuna
		</a>
	</aside>
</template>

<script>
export default {
	name: 'SideBar',

	props: ['fields'],

	methods: {
		scroll(e) {
			const target = e.target;
			const section = document.querySelector(target.hash);
			const offsetTop = section.offsetTop;
			const headerHeight = document.querySelector('header').scrollHeight || 0;

			window.scrollTo({ top: offsetTop - headerHeight - 20, behavior: 'smooth' });
		},
	},
};
</script>
