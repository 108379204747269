<template>
	<div>
		<div class="w-100 mb-60 py-60 px-80 border-light border-dashed border-width-3 rounded">
			<h1 class="mb-10">Skjøl</h1>

			<p class="mb-15 color-grey">Her kanst tú leggja viðkomandi skjøl til avtaluna.</p>

			<div
				v-for="file in files"
				:key="file.id"
				class="flex space-between align-center block py-15 px-20 color-blue background-white border-light rounded-lg shadow-lg hover:background-ultra-light-grey-300 mb-10"
			>
				<div class="heavy color-black">{{ file.name }}</div>

				<a
					href="#"
					@click.prevent="deleteFile(file)"
				>
					<div
						title="Strika skjal"
						style="width: 40px; height: 40px; display: grid; place-items: center"
						class="hover:color-red cursor-pointer"
					>
						<i
							class="fas fa-trash-alt"
							title="Strika"
							@click="confirmDeleteFile(file)"
						></i>
					</div>
				</a>
			</div>

			<div
				v-if="loading"
				class="relative flex justify-center mt-20"
			>
				<div class="flex justify-center">
					<i class="fas fa-spinner fa-spin fa-lg color-blue"></i>
				</div>
			</div>

			<a
				@click.prevent="modal.active = true"
				class="btn inline-block"
			>
				Legg skjal upp
			</a>

			<input
				type="file"
				ref="fileInput"
				@change="uploadFile"
				class="file-input-button display-none"
			/>

			<a
				ref="anchor"
				href="#"
				download
				class="hidden"
			></a>
		</div>

		<!-- File Type Modal -->
		<div
			@keydown.escape="modal.active = false"
			@click="modal.active = false"
			class="files-modal"
			:class="{ active: modal.active }"
		>
			<form
				@submit.prevent="$refs.fileInput.click()"
				@click.stop
				class="files-modal-container"
			>
				<a
					@click.prevent="modal.active = false"
					href="#"
					class="files-modal-close"
				>
					<i class="fas fa-times"></i>
				</a>

				<h2 class="mb-10">Vel slag</h2>

				<p class="mb-20 color-grey">Hvat slag av skjali er tað, tú leggur upp?</p>

				<div class="mb-10">
					<template v-for="type in types">
						<div
							v-if="!type.condition || type.condition()"
							:key="type.name"
							class="flex align-center mb-15"
						>
							<label class="cursor-pointer">
								<input
									v-model="fileType"
									type="radio"
									:value="type.fileName || type.name"
								/>

								<span class="pl-10">{{ type.name }}</span>

								<div
									v-if="type.description"
									style="font-weight: 400; font-size: 13px"
									class="color-grey mt-5"
								>
									{{ type.description }}
								</div>
							</label>
						</div>
					</template>
				</div>

				<div class="flex justify-end">
					<button
						:disabled="!fileType"
						class="btn"
						:class="{ 'opacity-50': !fileType }"
					>
						Legg upp
					</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import axios from 'axios';

export default {
	name: 'Files',

	props: {
		files: {
			type: Array,
			default: () => [],
		},
		agreementType: String,
		companyType: String,
	},

	emits: ['close'],

	data() {
		return {
			loading: false,
			file: null,
			fileType: '',
			modal: {
				active: false,
			},
			types: [
				{
					name: 'Handilstreytir',
				},
				{
					name: 'Tekningarútskrift',
				},
				{
					name: 'Skrásetingarprógv',
				},
				{
					name: 'Sjálvuppgáva',
					description: 'Um talan er eitt nýstovnað felag.',
				},
				{
					name: 'Roknskapur',
					description: 'Um talan ikki er eitt nýstovnað felag.',
				},
				{
					name: 'Aðalfundur',
					description: 'Avrit av seinasta aðalfundi, hvar tað framgongur, hvussu nevndin hevur skipað seg.',
					condition: () => {
						return this.companyType != 'P/f' && this.companyType != 'Sp/f' && this.companyType != 'fa.' && this.companyType != 'ÍVF';
					},
				},
				{
					name: 'Viðtøkur',
					description: 'Avrit av viðtøkum felagsins, hvar tað framgongur hvør ið hevur mynduleika at binda felagið.',
					condition: () => {
						return this.companyType != 'P/f' && this.companyType != 'Sp/f' && this.companyType != 'fa.' && this.companyType != 'ÍVF';
					},
				},
				{
					name: 'P-tals váttan',
					condition: () => {
						return this.agreementType == 'Vibrant';
					},
				},
				{
					name: 'Annað viðkomandi',
					fileName: 'Annað',
				},
			],
		};
	},

	methods: {
		getGradient() {
			const progress = this.document.progress;
			const remaining = 100 - progress;

			return `background: -webkit-linear-gradient(left, #ccedfa ${progress}%, white ${remaining}%)`;
		},

		uploadFile(e) {
			if (this.loading) {
				return;
			}

			const file = e.target.files[0];

			const token = this.$route.query.token;

			const formData = new FormData();

			this.loading = true;

			const extension = file.name.substring(file.name.lastIndexOf('.') + 1);
			const fileName = `${this.fileType} (${new Date().format('%d-%m-%y %H:%M:%S')}).${extension}`;

			formData.append('file', new File([file], fileName, { type: file.type }));

			axios
				.post(`/agreement-links/${token}/file`, formData)
				.then((response) => {
					this.$emit('upload', response.data);
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.fileType = '';

					this.loading = false;
				});

			window.dispatchEvent(new CustomEvent('file-uploaded', { detail: {} }));

			this.modal.active = false;
		},

		deleteFile(file) {
			if (!confirm('Ert tú vís/ur?')) {
				return;
			}

			const token = this.$route.query.token;

			axios
				.delete(`/agreement-links/${token}/file/${file.uuid}`)
				.then((response) => {
					console.log(response);

					this.$emit('delete', file.id);
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>

<style lang="scss" scoped>
.files-modal {
	position: fixed;
	inset: 0;
	z-index: 999;

	display: grid;
	place-items: center;

	padding: 8px;

	background: rgba($color: #383838, $alpha: 0.75);

	transition: 0.2s opacity ease;
}

.files-modal:not(.active) {
	opacity: 0;
	pointer-events: none;

	.files-modal-container {
		transform: translateY(16px);
	}
}

.files-modal-container {
	position: relative;

	width: 100%;
	max-width: 576px;

	padding: 32px;

	background: white;
	border-radius: 12px;

	transition: 0.2s transform ease;
}

.files-modal-close {
	position: absolute;
	top: 0;
	right: 0;
	width: 40px;
	height: 40px;

	display: grid;
	place-items: center;

	color: #696969;
}
</style>
