<template>
	<div id="client">
		<header>
			<div class="container">
				<img
					src="@/assets/terminal.svg"
					alt=""
				/>
			</div>
		</header>

		<div
			class="flex justify-center"
			v-if="loading"
		>
			<i class="fas fa-spinner fa-spin fa-2x color-blue"></i>
		</div>

		<template v-if="!loading">
			<!-- Admin: -->
			<template v-if="user">
				<PrivacyPolicy
					v-if="!agreeToPrivacyPolicy"
					:agreement="agreement.id"
					@agree="() => (agreeToPrivacyPolicy = true)"
				/>

				<main
					class="container"
					v-if="!loading && agreeToPrivacyPolicy"
				>
					<div class="grid">
						<side-bar :fields="fields"></side-bar>

						<div></div>

						<div class="forms">
							<div
								v-if="agreement"
								class="mb-30"
							>
								<router-link
									:to="{ name: 'AgreementShow', params: { id: agreement.id } }"
									class="color-grey hover:color-grey-800"
								>
									<i class="fas fa-arrow-left"></i>

									Aftur til avtalu
								</router-link>
							</div>

							<div
								id="documents"
								class="w-100 mb-60 py-60 px-80 border-light border-dashed border-width-3 rounded"
							>
								<h1 class="mb-10">Skjøl klár til undirskriving</h1>

								<p class="mb-15 color-grey">
									Skjøl sum eru klár at heinta og skriva undir. Undirskrivaði skjøl skulu uploadast niðanfyri, áðrenn avtalan kann gerast
									virkin.
								</p>

								<documents
									v-for="(document, index) in documents"
									:key="`document-${index}`"
									:agreement="agreement"
									:document="document"
									:no-upload="agreement.type && agreement.type.name == 'Stutttíðarleiga'"
									@download="downloadDocument"
									@file-uploaded="addFile(document.id, $event)"
								></documents>

								<a
									ref="anchor"
									href="#"
									download
									class="display-none"
								></a>
							</div>

							<customer-client
								v-model="customer"
								:agreement-type="agreement.type.name"
								@valid="updateFieldValidation('customer', $event)"
							></customer-client>

							<owners
								v-model="owners"
								v-if="agreement.type.name != 'Stutttíðarleiga'"
								:customer-id="customer.id"
								:agreement-uuid="agreement && agreement.uuid"
								@valid="updateFieldValidation('owners', $event)"
								@add-temporary="addTemporaryOwner"
								@update-temporary="updateTemporaryOwner"
								@delete-temporary="deleteTemporaryOwner"
							></owners>

							<bank
								v-if="agreement.type.name != 'Vibrant'"
								v-model="bankAccount"
								:bank-accounts="bankAccounts"
								:comment="data['bank-account-comment']"
								@add-account="
									(account) => {
										bankAccounts = [...bankAccounts, account];
									}
								"
								:agreement-type="agreement.type && agreement.type.name"
								:is-verified="data['bank-account-verified']"
								@valid="updateFieldValidation('bankAccount', $event)"
								@verified="data['bank-account-verified'] = $event"
								@comment="updateBankComment"
							></bank>

							<ECom
								v-if="hasFile('swedbank_e-com.pdf')"
								v-model="ecomData"
								:owner-signatures="ownerSignatures"
								:company-type="customer.companyType"
								@valid="updateFieldValidation('ecom', $event)"
							/>

							<Swedbank
								v-if="hasFile('swedbank_small.pdf') || hasFile('swedbank_large.pdf')"
								v-model="swedbankData"
								:owner-signatures="ownerSignatures"
								:company-type="customer.companyType"
								@valid="updateFieldValidation('swedbank', $event)"
							/>

							<Vibrant
								v-model="vibrantData"
								v-if="agreement.type && agreement.type.name == 'Vibrant'"
								:company-type="customer.companyType"
								@valid="updateFieldValidation('vibrant', $event)"
							/>

							<KYC
								v-if="agreement.type && agreement.type.name == 'KYC'"
								v-model="kycData"
								@valid="updateFieldValidation('kyc', $event)"
							/>

							<Files
								:files="uploadedFiles"
								:agreement-type="agreement.type.name"
								:company-type="customer.companyType"
								@upload="uploadFile"
								@delete="deleteFile"
							/>

							<div
								v-if="!agreement.sentForApproval && !agreement.approved && !agreement.active"
								class="flex align-center space-between"
							>
								<div class="flex align-center">
									<a
										href="#"
										class="btn hover:background-blue-600"
										@click.prevent="save(false)"
									>
										Goym fyribils
									</a>

									<div
										v-if="hasSaved"
										class="ml-10 color-green"
									>
										<i class="fas fa-check"></i>

										Goymt
									</div>
								</div>

								<div>
									<a
										href="#"
										class="btn hover:background-blue-600"
										:class="{ disabled: !isValid }"
										@click.prevent="save(true)"
									>
										Send til góðkenning
									</a>
								</div>
							</div>

							<div
								class="text-center"
								v-if="agreement.active"
							>
								<router-link
									:to="{ name: 'AgreementShow', params: { id: agreement.id } }"
									class="color-blue hover:color-blue-700"
								>
									Avtalan er virkin
								</router-link>
							</div>

							<div class="flex space-between mb-40">
								<div v-if="(agreement.sentForApproval && !agreement.approved) || (agreement.approved && !agreement.active)">
									<a
										href="#"
										class="btn hover:background-blue-600"
										@click.prevent="save(false)"
									>
										Goym fyribils
									</a>
								</div>

								<div
									v-if="agreement.sentForApproval && !agreement.approved"
									class="flex"
								>
									<a
										href="#"
										class="btn background-red mr-10"
										@click.prevent="setApproved(false)"
									>
										Avvís góðkenning
									</a>

									<a
										v-if="agreement.type.name != 'Vibrant'"
										href="#"
										class="btn hover:background-blue-600"
										@click.prevent="setApproved(true)"
									>
										<i
											v-if="loading"
											class="fas fa-spinner fa-spin color-white mr-10"
										></i>

										Send undirskriftarumbøn
									</a>
								</div>

								<div
									class="flex"
									v-if="agreement.approved && !agreement.active"
								>
									<a
										href="#"
										class="btn background-red"
										@click.prevent="setApproved(false)"
										>Strika undirskriftarumbøn</a
									>

									<!-- TODO: -->
									<a
										href="#"
										class="btn ml-10"
										@click.prevent="activate()"
										>Virkja avtalu</a
									>
								</div>
							</div>
						</div>
					</div>
				</main>
			</template>

			<!-- Client: Not sent for approval -->
			<template v-if="!agreement.sentForApproval && !agreement.approved && !user">
				<main class="container">
					<div class="grid">
						<side-bar :fields="fields"></side-bar>

						<div></div>

						<div class="forms">
							<div
								v-if="agreement.type.name == 'Stutttíðarleiga'"
								class="background-white w-100 mb-60 py-60 px-80 shadow-lg rounded"
							>
								<h1 class="mb-20">Stutttíðarleiga</h1>

								<div class="mb-20">
									<label class="mb-5">Avtala byrjar</label>

									<p class="color-grey-500">{{ new Date(agreement.from).format('%d/%m/%y kl. %H:%M') }}</p>
								</div>

								<div class="mb-20">
									<label class="mb-5">Avtala endar</label>

									<p class="color-grey-500">{{ new Date(agreement.to).format('%d/%m/%y kl. %H:%M') }}</p>
								</div>

								<div>
									<label class="mb-5">Tal av terminalum</label>

									<p class="color-grey-500">{{ agreement.numberOfTerminals }}</p>
								</div>
							</div>

							<customer-client
								v-model="customer"
								:agreement-type="agreement.type.name"
								@valid="updateFieldValidation('customer', $event)"
							></customer-client>

							<owners
								v-model="owners"
								v-if="agreement.type.name != 'Stutttíðarleiga'"
								:customer-id="customer.id"
								:agreement-uuid="agreement && agreement.uuid"
								@valid="updateFieldValidation('owners', $event)"
								@add-temporary="addTemporaryOwner"
								@update-temporary="updateTemporaryOwner"
								@delete-temporary="deleteTemporaryOwner"
							></owners>

							<bank
								v-if="agreement.type.name != 'Vibrant'"
								v-model="bankAccount"
								:bank-accounts="bankAccounts"
								:comment="data['bank-account-comment']"
								@add-account="
									(account) => {
										bankAccounts = [...bankAccounts, account];
									}
								"
								:agreement-type="agreement.type && agreement.type.name"
								:is-verified="data['bank-account-verified']"
								@valid="updateFieldValidation('bankAccount', $event)"
								@verified="data['bank-account-verified'] = $event"
								@comment="updateBankComment"
							></bank>

							<ECom
								v-if="hasFile('swedbank_e-com.pdf')"
								v-model="ecomData"
								:owner-signatures="ownerSignatures"
								:company-type="customer.companyType"
								@valid="updateFieldValidation('ecom', $event)"
							/>

							<Swedbank
								v-if="hasFile('swedbank_small.pdf') || hasFile('swedbank_large.pdf')"
								v-model="swedbankData"
								:owner-signatures="ownerSignatures"
								:company-type="customer.companyType"
								@valid="updateFieldValidation('swedbank', $event)"
							/>

							<Vibrant
								v-if="agreement.type && agreement.type.name == 'Vibrant'"
								v-model="vibrantData"
								:company-type="customer.companyType"
								@valid="updateFieldValidation('vibrant', $event)"
							/>

							<KYC
								v-if="agreement.type && agreement.type.name == 'KYC'"
								v-model="kycData"
								@valid="updateFieldValidation('kyc', $event)"
							/>

							<Files
								:files="uploadedFiles"
								:agreement-type="agreement.type.name"
								:company-type="customer.companyType"
								@upload="uploadFile"
								@delete="deleteFile"
							/>

							<div
								v-if="agreement.sentForApproval"
								class="flex justify-center mb-40 rounded"
								style="background-color: var(--color-green-200); place-items: center"
							>
								<div
									class="grid p-30"
									style="color: var(--color-green-900)"
								>
									Takk fyri. Umbønin er send og vit venda aftur, tá hon er viðgjørd.
								</div>
							</div>

							<div class="flex space-between mb-40">
								<div class="flex align-center">
									<a
										href="#"
										class="btn hover:background-blue-600"
										@click.prevent="save(false)"
									>
										Goym fyribils
									</a>

									<div
										v-if="hasSaved"
										class="ml-10 color-green"
									>
										<i class="fas fa-check"></i>

										Goymt
									</div>
								</div>

								<a
									href="#"
									class="btn hover:background-blue-600"
									:class="{ disabled: !isValid }"
									@click.prevent="save(true)"
								>
									Send til góðkenning
								</a>
							</div>
						</div>
					</div>
				</main>
			</template>

			<!-- Client: Sent For approval -->
			<template v-if="!user && agreement.sentForApproval && !agreement.approved">
				<main class="container">
					<div class="text-center">
						<h1 class="mb-10">Sent til góðkenningar</h1>

						<div class="color-grey">Avtalan er send til góðkenningar. Vinaliga halt okkum til góðar, meðan við viðgera avtaluna.</div>
					</div>
				</main>
			</template>

			<!-- Client: Approved -->
			<template v-if="!user && agreement.approved && !agreement.active">
				<main
					class="container"
					v-if="!loading"
					style="max-width: 768px"
				>
					<div
						v-if="!agreement.hasBeenApprovedByUser"
						class="forms"
					>
						<div
							v-if="documents.length"
							id="documents"
							class="w-100 mb-60 py-60 px-80 border-light border-dashed border-width-3 rounded"
						>
							<h1 class="mb-10">Skjøl klár til undirskriving</h1>

							<p class="mb-15 color-grey">
								Skjøl sum eru klár at heinta og skriva undir. Undirskrivaði skjøl skulu uploadast niðanfyri, áðrenn avtalan kann gerast virkin.
							</p>

							<documents
								v-for="(document, index) in documents"
								:key="`document-${index}`"
								:agreement="agreement"
								:document="document"
								:no-upload="agreement.type && agreement.type.name == 'Stutttíðarleiga'"
								@download="downloadDocument"
								@file-uploaded="addFile(document.id, $event)"
							></documents>

							<a
								ref="anchor"
								href="#"
								download
								class="display-none"
							></a>

							<div
								v-if="agreement.type && agreement.type.name == 'Stutttíðarleiga'"
								class="mb-5"
							>
								<label class="inline-flex align-center cursor-pointer">
									<div
										class="switch"
										:class="{ active: agreeDocuments }"
									>
										<input
											type="checkbox"
											v-model="agreeDocuments"
										/>

										<div></div>
									</div>

									<span class="ml-5">Eg vátti, at eg havi lisið og góðkenni viðløgdu avtalu.</span>
								</label>
							</div>
						</div>

						<div
							v-if="agreement.type && agreement.type.name == 'Stutttíðarleiga'"
							class="flex justify-end"
						>
							<a
								href="#"
								class="btn hover:background-blue-600"
								:class="{ disabled: !agreeDocuments }"
								@click.prevent="approveShortTermAgreement"
							>
								Góðkenn
							</a>
						</div>
					</div>

					<div
						v-if="agreement.hasBeenApprovedByUser"
						class="forms text-center"
					>
						<h1 class="mb-15">Váttanin er móttikin</h1>

						<p class="color-grey">Takk fyri</p>
					</div>
				</main>
			</template>

			<!-- Client: Active -->
			<template v-if="!user && agreement.active">
				<main
					class="container"
					v-if="!loading"
				>
					<div class="text-center">
						<h1 class="mb-10">Avtalan er virkin</h1>
					</div>
				</main>
			</template>
		</template>
	</div>
</template>

<script>
import axios from 'axios';

import ECom from '@/components/agreement-types/ECom.vue';
import KYC from '@/components/agreement-types/KYC.vue';
import Swedbank from '@/components/agreement-types/Swedbank.vue';
import Bank from '@/components/forms/Bank.vue';
import CustomerClient from '@/components/forms/CustomerClient.vue';
import Documents from '@/components/forms/Documents.vue';
import Files from '@/components/forms/Files.vue';
import Owners from '@/components/forms/Owners.vue';
import SideBar from '@/components/SideBar.vue';
import Vibrant from '../components/agreement-types/Vibrant.vue';
import PrivacyPolicy from '../components/PrivacyPolicy.vue';

export default {
	name: 'Client',

	components: {
		PrivacyPolicy,
		SideBar,
		CustomerClient,
		Owners,
		Bank,
		Documents,
		Files,
		ECom,
		Swedbank,
		KYC,
		Vibrant,
	},

	data() {
		return {
			agreeToPrivacyPolicy: false,

			user: null,
			loading: true,
			hasSaved: false,

			agreement: null,

			shortTermAgreement: false,

			customer: {},
			owners: [],
			bankAccounts: [],
			bankAccount: {},
			uploadedFiles: [],

			data: {},
			swedbankData: {},
			ecomData: {},
			kycData: {},
			vibrantData: {},

			fields: {
				customer: false,
				bankAccount: undefined,
				vibrant: undefined,
				owners: undefined,
				ecom: undefined,
				swedbank: undefined,
				kyc: undefined,
			},

			documents: [
				{
					uuid: '',
					id: '',
					title: '',
				},
			],

			windowEvent: null,

			agreeDocuments: false,
		};
	},

	async mounted() {
		const user = localStorage.getItem('user');

		if (!process.env.VUE_APP_PRIVACY_POLICY_URL) {
			this.agreeToPrivacyPolicy = true;
		}

		if (user) {
			this.user = JSON.parse(user);
		}

		if (this.user) {
			this.agreeToPrivacyPolicy = true;
		}

		const token = this.$route.query.token;

		window.addEventListener('keydown', this.saveOnCtrlS);

		this.loading = true;

		axios
			.get(`/agreement-links/${token}/agreement?userId=${this.user?.id ?? ''}`)
			.then((response) => {
				const data = response.data;

				this.data = data.data || {};
				this.ecomData = { ...this.data };
				this.swedbankData = { ...this.data };
				this.kycData = { ...this.data };
				this.vibrantData = { ...this.data };

				this.agreement = data;

				if (this.agreement.type.name != 'Stutttíðarleiga') {
					this.fields.owners = false;
				}

				this.customer = data.customer;

				if (this.customer.mobileNumbers) {
					this.customer.mobileNumbers = this.customer.mobileNumbers
						.split(',')
						.map((mobileNumber) => mobileNumber.replace('+298', '').trim())
						.filter((mobileNumber) => mobileNumber.length == 6);
				}

				if (!this.customer.mobileNumbers) {
					this.customer.mobileNumbers = [''];
				}

				let owners = data.customer.owners;

				for (const owner of owners) {
					if (this.willSignDocuments(owner)) {
						owner.signature = true;

						continue;
					}

					owner.signature = false;
				}

				for (const owner of owners) {
					if (this.data['contact-person-id'] == owner.id) {
						owner.isContactPerson = true;

						continue;
					}

					owner.isContactPerson = false;
				}

				this.owners = owners;

				if (this.data.owners) {
					this.owners = [...this.owners, ...this.data.owners];
				}

				this.bankAccounts = [...data.customer.bankAccounts];

				this.bankAccount = data.bankAccount;

				let originalFiles = [];

				originalFiles = [...data.originalFiles.map((originalFile) => ({ ...originalFile, file: null }))];

				for (const file of data.files) {
					const originalFile = originalFiles.find((originalFile) => originalFile.id == file.originalFile?.id);

					if (originalFile) {
						originalFile.file = file;
					}
				}

				const uploadedFiles = (this.data.uploadedFiles || []).filter((uploadedFile) =>
					this.agreement.files.map((file) => file.id).includes(uploadedFile.id)
				);

				this.uploadedFiles = uploadedFiles;

				this.documents = originalFiles;

				if (this.hasFile('swedbank_e-com.pdf')) {
					this.fields.ecom = false;
				}

				if (this.hasFile('swedbank_small.pdf') || this.hasFile('swedbank_large.pdf')) {
					this.fields.swedbank = false;
				}

				if (this.agreement.type && this.agreement.type.name == 'Vibrant') {
					this.fields.vibrant = false;
				}

				if (this.agreement.type && this.agreement.type.name == 'KYC') {
					this.fields.kyc = false;
				}
			})
			.catch((error) => {
				console.error(error);

				this.$router.push('/client/404');
			})
			.finally(() => {
				this.loading = false;
			});
	},

	destroyed() {
		window.removeEventListener('keydown', this.saveOnCtrlS);
	},

	watch: {
		owners: function (owners) {
			const signatures = owners.filter((owner) => owner.signature);

			if (!this.data) {
				return;
			}

			for (let i = 0; i < 3; ++i) {
				this.data[`CPRnummer${i == 0 ? '' : `_${i + 1}`}`] = '';
				this.data[`Navn ${i + 1} i blokbogstaver`] = '';
				this.data[`Skattepligtig i land${i == 0 ? '' : `_${i + 1}`}`] = '';
			}

			for (let i = 0; i < signatures.length; ++i) {
				if (i >= 3) {
					break;
				}

				this.data[`CPRnummer${i == 0 ? '' : `_${i + 1}`}`] = signatures[i].ssn;
				this.data[`Navn ${i + 1} i blokbogstaver`] = signatures[i].name;
				this.data[`Skattepligtig i land${i == 0 ? '' : `_${i + 1}`}`] = signatures[i].taxationCountry;
			}

			const contactPerson = owners.find((owner) => owner.isContactPerson);

			if (contactPerson) {
				this.data['contact-person-id'] = contactPerson.id;
			} else {
				this.data['contact-person-id'] = null;
			}

			this.save();
		},
	},

	computed: {
		ownerSignatures() {
			return this.owners.filter((owner) => owner.signature);
		},

		isValid() {
			for (const field of Object.values(this.fields)) {
				if (field === false) {
					return false;
				}
			}

			return true;
		},
	},

	methods: {
		updateBankComment(comment) {
			this.data['bank-account-comment'] = comment;
		},

		saveOnCtrlS(e) {
			const ctrl = e.ctrlKey || e.metaKey;

			if (ctrl && e.key == 's') {
				this.save(false);

				e.preventDefault();
			}
		},

		willSignDocuments(owner) {
			if (!this.data) {
				return;
			}

			for (let i = 0; i < 3; ++i) {
				if (this.data[`CPRnummer${i == 0 ? '' : `_${i + 1}`}`] == owner.ssn) {
					return true;
				}
			}

			return false;
		},

		updateFieldValidation(field, value) {
			this.fields[field] = value;
		},

		hasFile(fileName) {
			if (!this.agreement || !this.agreement.originalFiles) {
				return false;
			}

			for (const file of this.agreement.originalFiles) {
				if (file.path == fileName) {
					return true;
				}
			}

			return false;
		},

		async addTemporaryOwner(owner) {
			this.data.owners = this.data.owners ? [...this.data.owners, owner] : [owner];

			this.owners = [...this.owners, owner];

			await this.save();
		},

		async updateTemporaryOwner(owner) {
			const ownerId = owner.id;

			const index = this.data.owners.findIndex((owner) => owner.id == ownerId);

			this.data.owners[index] = owner;

			await this.save();
		},

		async deleteTemporaryOwner(ownerId) {
			this.data.owners = this.data.owners.filter((owner) => owner.id != ownerId);

			await this.save();
		},

		activate() {
			if (this.loading) {
				return;
			}

			this.loading = true;

			axios
				.post(`/agreements/${this.agreement.id}/toggle-active`)
				.then(() => {
					this.agreement.active = true;
				})
				.catch((error) => {
					console.error('Unable to toggle active on agreement', error.response);
				})
				.finally(() => {
					this.loading = false;
				});
		},

		approveShortTermAgreement() {
			const token = this.$route.query.token;

			axios
				.put(`/agreement-links/${token}/approve-short-term-agreement`)
				.then(() => {
					this.agreement.hasBeenApprovedByUser = true;
				})
				.catch((error) => {
					console.error('Unable to activate short term agreement', error.response);
				});
		},

		uploadFile(file) {
			this.uploadedFiles = [
				...this.uploadedFiles,
				{
					id: file.id,
					uuid: file.uuid,
					name: file.original_name,
				},
			];

			this.save();
		},

		deleteFile(fileId) {
			this.uploadedFiles = this.uploadedFiles.filter((uploadedFile) => uploadedFile.id != fileId);
		},

		async save(notifyTerminal = false) {
			if (notifyTerminal) {
				if (!confirm('Ert tú vís/ur?')) {
					return;
				}
			}

			const token = this.$route.query.token;

			let data = { ...this.data };

			if (this.hasFile('swedbank_e-com.pdf')) {
				data = { ...data, ...this.ecomData };
			}

			if (this.hasFile('swedbank_small.pdf') || this.hasFile('swedbank_large.pdf')) {
				data = { ...data, ...this.swedbankData };
			}

			if (this.agreement.type && this.agreement.type.name == 'Vibrant') {
				data = { ...data, ...this.vibrantData };
			}

			if (this.agreement.type && this.agreement.type.name == 'KYC') {
				data = { ...data, ...this.kycData };
			}

			data = { ...data, uploadedFiles: this.uploadedFiles };

			const updateAgreementDto = {
				notifyTerminal,

				customer: {
					...this.customer,
					mobileNumbers: this.customer.mobileNumbers
						.filter((mobileNumber) => mobileNumber)
						.filter((mobileNumber) => !isNaN(mobileNumber))
						.map((mobileNumber) => `+298${mobileNumber}`)
						.join(','),
				},

				bankAccount: this.bankAccount || null,

				data,
			};

			axios
				.put(`/agreement-links/${token}/agreement`, updateAgreementDto)
				.then(() => {
					this.hasSaved = true;

					setTimeout(() => {
						this.hasSaved = false;
					}, 3000);

					if (notifyTerminal) {
						axios
							.post(`/agreement-links/${token}/send-for-approval`)
							.then(() => {
								this.agreement.sentForApproval = new Date();

								if (this.agreement.type.name == 'Stutttíðarleiga') {
									this.agreement.approved = true;
								}
							})
							.catch(() => {});
					}
				})
				.catch((error) => {
					console.error('Unable to post agreement', error);
				});
		},

		addFile(documentId, file) {
			const document = this.documents.find((document) => document.id == documentId);

			if (!document) {
				return;
			}

			document.file = file;
		},

		async downloadDocument(url) {
			await this.save();

			this.$refs.anchor.href = url;

			this.$nextTick(() => {
				this.$refs.anchor.click();
			});
		},

		async setApproved(approved) {
			if (!confirm('Ert tú vís/ur?')) {
				return;
			}

			this.loading = true;

			axios
				.post(`/agreements/${this.agreement.id}/set-approved`, {
					approved,
				})
				.then(() => {
					this.agreement.approved = approved;
					this.agreement.sentForApproval = null;
				})
				.catch(() => {})
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>

<style scoped>
.grid {
	grid-template-columns: 30% 70%;
}

.forms {
	max-width: 100%;
}

@media (max-width: 992px) {
	aside {
		display: none;
	}

	.grid {
		grid-template-columns: 100%;
	}
}
</style>
