<template>
	<div
		id="vibrant"
		class="background-white w-100 mb-60 py-60 px-80 shadow-lg rounded"
	>
		<h1 class="mb-10">Vibrant</h1>

		<p class="mb-30 color-grey">Vinarliga útfyll hesar teigar til Vibrant avtaluna.</p>

		<!-- Loader -->
		<div
			v-if="loading"
			href="#"
			class="relative flex justify-center"
		>
			<div class="flex justify-center">
				<i class="fas fa-spinner fa-spin fa-lg color-blue"></i>
			</div>
		</div>

		<div v-if="!loading">
			<!-- Salgssted -->
			<h2 class="mb-20">Sølustað</h2>

			<div class="flex align-center mb-20">
				<label class="cursor-pointer">
					<input
						type="checkbox"
						v-model="form.sameAddress"
						class="mr-10"
					/>

					Set kross her um sølustað er tað sama sum lóggildugur bústaður
				</label>
			</div>

			<transition name="slide-fade">
				<div v-if="!form.sameAddress">
					<div
						class="mb-20"
						style="font-style: italic"
					>
						Útfyll hesar upplýsingar fyri sølustaðið
					</div>

					<div class="grid grid-2 grid-gap-x-20">
						<InputGroup
							v-model="form.salesPlace"
							label="Navn"
							:required="true"
						/>

						<InputGroup
							v-model="form.phoneNumber"
							label="Telefon"
							:required="true"
						/>

						<InputGroup
							v-model="form.address"
							label="Bústaður"
							:required="true"
						/>

						<InputGroup
							v-model="form.postalCodeAndCity"
							label="Postnummar og bygd/býur"
							:required="true"
						/>

						<InputGroup
							v-model="form.contactPerson"
							label="Kontaktpersónur"
							:required="true"
						/>

						<InputGroup
							v-model="form.contactPersonEmail"
							label="Teldupostur hjá kontaktpersóni"
							:required="true"
						/>
					</div>
				</div>
			</transition>

			<hr class="mb-30" />

			<!-- Virksomhed/Branche -->
			<h2 class="mb-20">Vinnugrein</h2>

			<InputGroup
				v-model="form.description"
				label="Stutt lýsing av vørum ella tænastu"
				:required="true"
			/>

			<div class="grid grid-2 grid-gap-x-20">
				<InputGroup
					v-model="form.expectedCard"
					label="Vænta kortsøla um ári (VISA / MasterCard)"
					:required="true"
				/>

				<InputGroup
					v-model="form.expectedAverage"
					label="Væntað miðalkeyp pr. sølu"
					:required="true"
				/>
			</div>

			<hr class="mb-30" />

			<h2 class="mb-20">Skjøl</h2>

			<p class="mb-20 color-grey">Til Vibrant avtalu er tørvur á hesum skjølum:</p>

			<ul v-if="['P/f', 'Sp/f'].includes(companyType)">
				<li class="mb-5">
					<strong class="block">Skrásetingarprógv úr Vinnuskránni</strong>
					<em
						class="color-grey"
						style="font-size: 13px"
					>
						Finst á Vinnuglugganum hjá Taks
					</em>
				</li>

				<li class="mb-5">
					<strong class="block">Tekningarútskrift</strong>
					<em
						class="color-grey"
						style="font-size: 13px"
					>
						Finst á www.skraseting.fo
					</em>
				</li>

				<li class="mb-5">
					<strong class="block">Eigarayvirlit</strong>
					<em
						class="color-grey"
						style="font-size: 13px"
					>
						Um tað ikki er upplýst á www.skraseting.fo
					</em>
				</li>

				<li class="mb-5">
					<strong class="block">P-talsváttanir hjá øllum persónum, ið binda felagið ella eiga 25% ella meira</strong>
					<em
						class="color-grey"
						style="font-size: 13px"
					>
						Vangin → Tænastuveitarar → Umhvørvisstovan → Sjálvgreiðslur → P-talsváttan → Tak niður
					</em>
				</li>
			</ul>

			<ul v-else-if="['fa.'].includes(companyType)">
				<li class="mb-5">
					<strong class="block">Skrásetingarprógv úr Vinnuskránni</strong>
					<em
						class="color-grey"
						style="font-size: 13px"
					>
						Finst á Vinnuglugganum hjá Taks
					</em>
				</li>

				<li class="mb-5">
					<strong class="block">P-talsváttan</strong>
					<em
						class="color-grey"
						style="font-size: 13px"
					>
						Vangin → Tænastuveitarar → Umhvørvisstovan → Sjálvgreiðslur → P-talsváttan → Tak niður
					</em>
				</li>
			</ul>

			<ul v-else-if="['ÍVF'].includes(companyType)">
				<li class="mb-5">
					<strong class="block">Skrásetingarprógv úr Vinnuskránni</strong>
					<em
						class="color-grey"
						style="font-size: 13px"
					>
						Finst á Vinnuglugganum hjá Taks
					</em>
				</li>

				<li class="mb-5">
					<strong class="block">P-talsváttanir hjá øllum persónum, ið binda felagið ella eiga 25% ella meira</strong>
					<em
						class="color-grey"
						style="font-size: 13px"
					>
						Vangin → Tænastuveitarar → Umhvørvisstovan → Sjálvgreiðslur → P-talsváttan → Tak niður
					</em>
				</li>

				<li class="mb-5">
					<strong class="block">Eigarayvirlit</strong>
					<em
						class="color-grey"
						style="font-size: 13px"
					>
						Um tað ikki er upplýst á www.skraseting.fo
					</em>
				</li>
			</ul>

			<ul v-else>
				<li class="mb-5">
					<strong class="block">Skrásetingarprógv úr Vinnuskránni</strong>
					<em
						class="color-grey"
						style="font-size: 13px"
					>
						Finst á Vinnuglugganum hjá Taks
					</em>
				</li>

				<li class="mb-5">
					<strong class="block">P-talsváttanir hjá øllum persónum, ið binda felagið ella eiga 25% ella meira</strong>
					<em
						class="color-grey"
						style="font-size: 13px"
					>
						Vangin → Tænastuveitarar → Umhvørvisstovan → Sjálvgreiðslur → P-talsváttan → Tak niður
					</em>
				</li>

				<li class="mb-5">
					<strong class="block">Avrit av seinasta aðalfundi, við dato og undirskrift, har tað framgongur, hvussu nevndin hevur skipað seg</strong>
				</li>

				<li class="mb-5">
					<strong class="block">Avrit av viðtøkum felagsins, har tað framgongur, hvør hevur myndugleika at binda felagið</strong>
				</li>
			</ul>

			<p
				class="color-grey"
				style="font-size: 12px"
			>
				<strong>GG:</strong> Tú leggur skjal upp við at trýsta á "Legg skjal upp" niðanfyri.
			</p>
		</div>
	</div>
</template>

<script>
import InputGroup from '@/components/InputGroup.vue';

import { required, requiredIf } from 'vuelidate/lib/validators';

export default {
	name: 'Vibrant',

	props: ['value', 'companyType'],

	components: {
		InputGroup,
	},

	data() {
		return {
			signatures: 1,
			loading: true,
			form: {
				sameAddress: false,
				salesPlace: '',
				phoneNumber: '',
				address: '',
				postalCodeAndCity: '',
				contactPerson: '',
				contactPersonEmail: '',
				description: '',
				expectedCard: '',
				expectedAverage: '',
			},
		};
	},

	mounted() {
		this.load(this.value);

		this.loading = false;
	},

	methods: {
		load(data) {
			if (!data) {
				return;
			}

			for (const key of Object.keys(this.form)) {
				this.form[key] = data[key];
			}

			// Same address is not always included in the data, but we need it to always be a boolean.
			this.sameAddress = !!this.sameAddress;
		},
	},

	validations: {
		form: {
			sameAddress: {
				required,
			},

			salesPlace: {
				required: requiredIf((form) => {
					return !form.sameAddress;
				}),
			},

			phoneNumber: {
				required: requiredIf((form) => {
					return !form.sameAddress;
				}),
			},

			address: {
				required: requiredIf((form) => {
					return !form.sameAddress;
				}),
			},

			postalCodeAndCity: {
				required: requiredIf((form) => {
					return !form.sameAddress;
				}),
			},

			contactPerson: {
				required: requiredIf((form) => {
					return !form.sameAddress;
				}),
			},

			contactPersonEmail: {
				required: requiredIf((form) => {
					return !form.sameAddress;
				}),
			},

			description: {
				required,
			},

			expectedCard: {
				required,
			},

			expectedAverage: {
				required,
			},
		},
	},

	watch: {
		form: {
			handler(value) {
				this.$emit('input', { ...value });
				this.$emit('valid', !this.$v.form.$invalid);
			},

			deep: true,
		},
	},
};
</script>
