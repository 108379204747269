<template>
	<div class="container-md mb-30">
		<div class="background-white py-50 px-60 shadow-lg rounded">
			<h1 class="mb-30">Privatlívspolitikkur</h1>

			<p class="mb-20">Vinarliga vátta privatlívspolitikkin, áðrenn tú heldur fram.</p>

			<label class="inline-flex align-center cursor-pointer mb-20">
				<div
					class="switch"
					:class="{ active: agree }"
				>
					<input
						type="checkbox"
						v-model="agree"
					/>

					<div></div>
				</div>

				<span class="ml-10">
					Eg vátti, at eg havi lisið og góðkenni

					<a
						:href="url"
						target="_blank"
						class="color-blue"
					>
						privatlívspolitikkin
					</a>
				</span>
			</label>

			<div class="flex mt-10">
				<button
					@click="submit"
					:class="{ disabled: !agree }"
					class="btn"
				>
					Vátta
				</button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'PrivacyPolicy',

	props: ['agreement'],

	data() {
		return {
			agree: false,
		};
	},

	mounted() {
		if (sessionStorage.getItem(`agreement.${this.agreement}.accepted`)) {
			this.submit();
		}
	},

	computed: {
		url() {
			return process.env.VUE_APP_PRIVACY_POLICY_URL;
		},
	},

	methods: {
		submit() {
			this.$emit('agree');

			sessionStorage.setItem(`agreement.${this.agreement}.accepted`, true);
		},
	},
};
</script>
