<template>
	<div
		id="owners"
		class="background-white w-100 mb-60 py-60 px-80 shadow-lg rounded"
	>
		<h1 class="mb-10">Persónar</h1>

		<div class="mb-30 color-grey">
			<p>Vinarliga útfyll persónsupplýsingar fyri allar ítøkiligu persónar sum:</p>

			<ul>
				<li>Eiga 25% ella meira í fyritøkuni.</li>

				<li>Skriva undir vegna fyritøkuna.</li>

				<li>Er kontaktpersónur til hesa avtaluna.</li>
			</ul>
		</div>

		<div
			v-for="(owner, index) in owners"
			:key="`owner-${index}`"
			class="relative block w-100 py-20 px-30 mb-20 border-light rounded-lg hover:background-ultra-light-grey-300"
		>
			<div class="owner">
				<div>
					<div
						class="color-black heavy hover:color-blue cursor-pointer"
						@click="editOwner(index)"
					>
						{{ owner.name || 'John/Jane Doe' }}
					</div>

					<div class="color-grey">{{ owner.ownership }}%</div>

					<small
						v-if="!owner.temporary && !owner.skrivaId"
						class="mt-5 color-red"
					>
						* Dagfør persónin fyri at leggja Undirskriftar ID inn.
					</small>

					<small
						v-if="owner.temporary"
						class="mt-5 color-red"
					>
						Fyribils
					</small>
				</div>

				<div class="owner-checkboxes flex flex-column">
					<label
						v-if="owner.canSign"
						class="mt-5 color-grey-800 cursor-pointer"
						style="font-weight: 400; transition: 0.2s opacity ease"
					>
						<input
							:checked="owner.signature"
							type="checkbox"
							class="mr-5"
							@change="setOwnerSignature($event, owner)"
						/>

						Skrivar undir hesa avtalu
					</label>

					<label
						class="mt-5 color-grey-800 cursor-pointer"
						:class="{ 'opacity-40 pointer-events-none': contactPerson && contactPerson.id != owner.id }"
						style="font-weight: 400; transition: 0.2s opacity ease"
					>
						<input
							:checked="owner.isContactPerson"
							type="checkbox"
							class="mr-5"
							@change="setOwnerIsContactPerson($event, owner)"
						/>

						Kontaktpersónur fyri hesa avtalu
					</label>

					<i
						title="Kontaktpersónur"
						class="fas fa-check-circle color-blue"
						:class="{ 'opacity-0': !owner.isContactPerson }"
						style="position: absolute; top: 50%; right: -18px; transform: translateY(-50%); transition: 0.2s opacity ease"
					></i>
				</div>
			</div>
		</div>

		<div
			class="plus flex align-center justify-center py-25 px-30 mb-10 color-blue border-light rounded-lg hover:background-ultra-light-grey-300 cursor-pointer"
			@click.prevent="overlayVisible = true"
		>
			Legg persón afturat
		</div>

		<owner-overlay
			v-model="owner"
			:agreement-uuid="agreementUuid"
			:visible="overlayVisible"
			:editing="editing"
			:has-max-signatures="false"
			:contact-person="contactPerson"
			@close="resetEditing"
			@saveOwner="saveOwner"
			@deleteOwner="deleteOwner"
			@delete-temporary="
				$emit('delete-temporary', $event);
				owners = owners.filter((owner) => owner.id != $event);
			"
			@update-file-url="updateFileUrl"
		></owner-overlay>
	</div>
</template>

<script>
import axios from 'axios';

import { v4 as uuid } from 'uuid';

import OwnerOverlay from '@/components/OwnerOverlay.vue';

export default {
	name: 'Owners',

	components: {
		OwnerOverlay,
	},

	props: ['value', 'customerId', 'agreementUuid', 'throughAgreementLink'],

	data() {
		return {
			editing: false,

			overlayVisible: false,

			owners: [],

			owner: {
				name: '',
				ssn: '',
				email: '',
				jobTitle: '',
				isOwner: undefined,
				ownership: '',
				canSign: undefined,
				signature: false,
				isContactPerson: undefined,
				livingCountry: 'Føroyar',
				taxationCountry: 'Føroyar',
				pep: undefined,
				file: null,
			},
		};
	},

	watch: {
		owners: {
			immediate: true,

			deep: true,

			handler(value) {
				if (!value) {
					return;
				}

				if (value.length <= 0) {
					return this.$emit('valid', false);
				}

				this.$emit('input', value);
				this.$emit(
					'valid',
					// Has signers
					this.signatures?.length &&
						// Has contact person
						!!this.contactPerson &&
						// Signers have Skriva ID
						!this.signatures?.some?.((person) => !person.skrivaId)
				);
			},
		},

		value: {
			immediate: true,

			deep: true,

			handler(value) {
				if (!this.owners.length) {
					this.owners = value;
				}
			},
		},
	},

	computed: {
		signatures() {
			return this.value.filter((owner) => owner.signature);
		},

		contactPerson() {
			return this.owners.find((owner) => owner.isContactPerson);
		},
	},

	methods: {
		updateFileUrl(url) {
			this.owner.file = { ...this.owner.file, url };
		},

		resetEditing() {
			this.editing = false;

			this.owner.id = '';
			this.owner.isOwner = undefined;
			this.owner.canSign = undefined;
			this.owner.signature = undefined;
			this.owner.isContactPerson = undefined;
			this.owner.name = '';
			this.owner.jobTitle = '';
			this.owner.email = '';
			this.owner.ssn = '';
			this.owner.livingCountry = 'Føroyar';
			this.owner.taxationCountry = 'Føroyar';
			this.owner.ownership = '';
			this.owner.pep = undefined;
			this.owner.file = '';

			this.overlayVisible = false;
		},

		setOwnerSignature(e, owner) {
			const copy = { ...owner };
			copy.signature = e.target.checked;

			this.owners.splice(this.owners.indexOf(owner), 1, copy);
		},

		setOwnerIsContactPerson(e, owner) {
			const copy = { ...owner };
			copy.isContactPerson = e.target.checked;

			this.owners.splice(this.owners.indexOf(owner), 1, copy);
		},

		editOwner(ownerIndex) {
			const owner = this.owners.find((_, index) => index === ownerIndex);

			this.owner = { ...owner };

			this.editing = true;

			this.overlayVisible = true;
		},

		deleteOwner(ownerToDelete) {
			this.owners = this.owners.filter((owner) => owner.id != ownerToDelete.id);
		},

		saveOwner(temporary) {
			const owner = {
				id: this.owner.id,
				name: this.owner.name,
				email: this.owner.email,
				isOwner: this.owner.isOwner,
				jobTitle: this.owner.jobTitle,
				ownership: this.owner.ownership || 0,
				canSign: this.owner.canSign,
				ssn: this.owner.ssn,
				livingCountry: this.owner.livingCountry,
				taxationCountry: this.owner.taxationCountry,
				pep: this.owner.pep,
				file: { uuid: this.owner.file && this.owner.file.uuid },
				customer: { id: this.customerId },
			};

			let ownerCopy = { ...this.owner };

			if (temporary) {
				const copy = {
					...owner,
					temporary,
					isContactPerson: this.owner.isContactPerson,
				};

				const ownerId = owner.id;
				const index = this.owners.findIndex((owner) => owner.id == ownerId);

				// Add new owner
				if (index < 0) {
					copy.id = uuid();

					this.$emit('add-temporary', copy);

					this.owners.push(copy);
				} else {
					// Update existing owner
					this.owners[index] = copy;

					this.$emit('update-temporary', copy);
				}

				return;
			}

			const token = this.$route.query.token;

			// Add new Owner
			if (!this.editing || ownerCopy.temporary) {
				if (ownerCopy.temporary) {
					const ownerId = owner.id;

					this.$emit('delete-temporary', ownerId);
					this.owners = this.owners.filter((owner) => owner.id != ownerId);
				}

				delete owner.id;
				delete owner.temporary;

				axios
					.post(`/agreement-links/${token}/owner`, owner)
					.then((response) => {
						if (!this.editing) {
							let owner = { ...response.data };

							owner.signature = this.owner.signature;
							owner.isContactPerson = this.owner.isContactPerson;

							this.owners.push({ ...owner });
						}
					})
					.catch((error) => console.log(error));

				return;
			}

			// Update existing owner
			if (this.editing) {
				const ownerIndexToEdit = this.owners.findIndex((owner) => this.owner.id === owner.id);

				if (isNaN(ownerIndexToEdit)) {
					return;
				}

				axios
					.patch(`/agreement-links/${token}/owner/${owner.id}`, owner)
					.then((response) => {
						let owner = response.data;

						owner.signature = ownerCopy.signature;
						owner.isContactPerson = ownerCopy.isContactPerson;

						let owners = [...this.owners];

						owners[ownerIndexToEdit] = owner;

						this.owners = owners;

						if (this.owner.signature) {
							this.setOwnerSignature({ target: { checked: true } }, owner);
						}

						if (this.owner.isContactPerson) {
							this.setOwnerIsContactPerson({ target: { checked: true } }, owner);
						}
					})
					.catch((error) => console.log(error));

				this.resetEditing();
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.owner {
	@media (min-width: 768px) {
		display: flex;
		justify-content: space-between;
	}
}

.owner-checkboxes {
	@media (min-width: 768px) {
		align-items: flex-end;
	}
}
</style>
